<template>
  <v-container>
    <div>
      <v-btn color="primary" @click="back" class="noprint mr-3">
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-btn>
    </div>
    <div class="mt-3">
      <iframe
        :src="url"
        frameborder="0"
        type="application/pdf"
        width="100%"
        height="800px"
      ></iframe>
    </div>
  </v-container>
</template>

<script>
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import moment from "moment";
import angkaTerbilang from "@develoka/angka-terbilang-js";

export default {
  name: "print-preview-kasbon",
  props: {
    id: Number,
  },
  data() {
    return {
      kasbon: {},
      url: "",
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    async getKasbon() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("generalKasbon/getById", this.id)
        .then(response => {
          this.kasbon = response.data;
          this.createPdf();
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      if (value === null) {
        return "";
      }
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    createPdf() {
      const doc = new jsPDF();

      doc.setFont("Times", "bold");
      doc.setFontSize(14);
      doc.text("KAS BON", 5, 10);
      doc.line(5, 11, 28, 11);

      doc.rect(78, 6, 4, 4);
      doc.setFont("Times", "normal");
      doc.setFontSize(10);
      doc.text("Uang sudah diterima", 85, 9);

      doc.text("Tanggal :", 137, 9);
      doc.line(157, 10, 205, 10);
      doc.text(moment(this.kasbon.kasbonDate).format("DD-MMM-YY"), 157, 9);
      doc.text("Assign# :", 137, 15);
      doc.text(`${this.kasbon.kasbonNumber}`, 157, 15);
      if (this.kasbon.oldKasbon !== "") {
        doc.text(`(${this.kasbon.oldKasbon})`, 170, 15);
      }

      doc.text("Perusahaan", 5, 25);
      doc.text(":", 39, 25);
      doc.line(42, 26, 205, 26);
      doc.text("PT. GRAHA SUMBER PRIMA ELEKTRONIK", 42, 25);

      doc.text("Nama", 5, 30);
      doc.text(":", 39, 30);
      doc.line(42, 31, 120, 31);
      doc.text(this.kasbon.name, 42, 30);
      doc.text("Divisi", 130, 30);
      doc.text(":", 140, 30);
      doc.line(155, 31, 205, 31);
      doc.text(this.kasbon.division, 155, 30);

      doc.text("Jumlah", 5, 35);
      doc.text(":", 39, 35);
      doc.line(42, 36, 120, 36);
      doc.text(`Rp.      ${this.formatPrice(this.kasbon.totalAmount)}`, 42, 35);

      doc.text("Terbilang", 5, 40);
      doc.text(":", 39, 40);
      doc.line(42, 41, 205, 41);
      doc.text(`${angkaTerbilang(this.kasbon.totalAmount)} rupiah`, 42, 40);

      doc.text("Pembayaran", 5, 60);
      doc.text("Tunai", 42, 60);
      if (this.kasbon.paymentMethod === "Cash") {
        doc.rect(68, 57, 4, 4);
        doc.text("X", 72, 60);
      } else {
        doc.rect(68, 57, 4, 4);
      }
      doc.text("Transfer", 94, 60);
      if (this.kasbon.paymentMethod === "Transfer" && this.kasbon.bankAccountNumber !== null) {
        doc.rect(124, 57, 4, 4);
        doc.text("X", 125, 60);
        doc.text("Nama Penerima", 10, 65);
        doc.text(":", 39, 65);
        doc.line(42, 66, 120, 66);
        doc.text(this.kasbon.name, 42, 65);
        doc.text("Tgl Transfer", 130, 65);
        doc.text(":", 150, 65);
        doc.line(155, 66, 205, 66);

        doc.text("Bank", 10, 70);
        doc.text(":", 39, 70);
        doc.line(42, 71, 205, 71);
        doc.text("BCA", 42, 70);

        doc.text("No Rekening", 10, 75);
        doc.text(":", 39, 75);
        doc.line(42, 77, 205, 77);
        doc.text(this.kasbon.bankAccountNumber, 42, 75);
      } else {
        doc.rect(124, 57, 4, 4);
        doc.text("Nama Penerima", 10, 65);
        doc.text(":", 39, 65);
        doc.line(42, 66, 120, 66);
        doc.text("Tgl Transfer", 130, 65);
        doc.text(":", 150, 65);
        doc.line(155, 66, 205, 66);

        doc.text("Bank", 10, 70);
        doc.text(":", 39, 70);
        doc.line(42, 71, 205, 71);
        doc.text("BCA", 42, 70);

        doc.text("No Rekening", 10, 75);
        doc.text(":", 39, 75);
        doc.line(42, 77, 205, 77);
      }

      doc.text("Untuk Keperluan", 5, 82);
      doc.text(":", 39, 82);
      doc.text(this.kasbon.remark, 42, 82);

      let y = 100;
      for (let i = 0; i < this.kasbon.items.length; i++) {
        let text = `${this.kasbon.items[i].description} ${moment(
          this.kasbon.items[i].startDate
        ).format("DD")}-${moment(this.kasbon.items[i].endDate).format("DD")}/${moment(
          this.kasbon.items[i].startDate
        ).format("MMM")}`;
        let splitText = doc.splitTextToSize(text, 150);
        let textHeight = doc.getTextDimensions(splitText).h;
        if (i == 0) {
          doc.text(splitText, 42, y, { maxWidth: 150 });
          doc.text(this.formatPrice(this.kasbon.items[i].amount), 205, y + textHeight, {
            align: "right",
          });
          doc.line(42, y + 1 + textHeight, 205, y + 1 + textHeight);
        } else {
          doc.text(splitText, 42, y, { maxWidth: 150 });
          doc.text(this.formatPrice(this.kasbon.items[i].amount), 205, y + textHeight, {
            align: "right",
          });
          doc.line(42, y + textHeight + 1, 205, y + textHeight + 1);
        }
        y += 5 + textHeight + 1;
      }

      doc.autoTable({
        margin: { left: 5, right: 5 },
        startY: y + 10,
        theme: "plain",
        head: [["Diminta Oleh :", "Diketahui Oleh :", "Disetujui Oleh :", "Diterima Oleh :"]],
        body: [
          [
            {
              content: "",
              styles: { valign: "middle", halign: "center", lineWidth: 0.1, fontStyle: "normal" },
            },
            {
              content: "",
              styles: { valign: "middle", halign: "center", lineWidth: 0.1, fontStyle: "normal" },
            },
            {
              content: "",
              styles: { valign: "middle", halign: "center", lineWidth: 0.1, fontStyle: "normal" },
            },
            {
              content: "Tgl. ___________________",
              styles: { valign: "top", halign: "left", lineWidth: 0.1, fontStyle: "normal" },
            },
          ],
        ],
        styles: {
          valign: "middle",
          tableWidth: "auto",
          lineWidth: 0.1,
          halign: "left",
          lineColor: "black",
          minCellHeight: 30,
        },
        headStyles: {
          valign: "middle",
          halign: "center", //'center' or 'right',
          tableWidth: "auto",
          minCellHeight: 0,
        },
        columnStyles: {
          0: { cellWidth: 50 },
          1: { cellWidth: 50 },
          2: { cellWidth: 50 },
          3: { cellWidth: 50 },
          // etc
        },
      });

      this.url = doc.output("datauristring");
    },
  },
  created() {
    this.getKasbon();
  },
};
</script>

<style></style>
